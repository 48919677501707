@font-face {
  font-family: 'Geomanist';
  src: url('./fonts/Geomanist-Light.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Geomanist';
  src: url('./fonts/Geomanist-Regular.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Geomanist';
  src: url('./fonts/Geomanist-Medium.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Geomanist';
  src: url('./fonts/Geomanist-Bold.otf') format('opentype');
  font-weight: 700;
}

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: 'Geomanist', sans-serif;
}

.gradient-text {
  background: linear-gradient(to top right, #B0D99C, #68BE6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline;
}

.gradient-text::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px; /* Regola lo spessore della linea di sottolineatura */
  background: linear-gradient(to top right, #B0D99C, #68BE6A);
  bottom: 7px;
  left: 50%; /* Centra il pseudo-elemento */
  width: calc(100% - 8px); /* Riduce la larghezza totale di 20px */
  transform: translateX(-50%); 
}

.gradient-text-1 {
  background: linear-gradient(to top right, #B0D99C, #68BE6A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  display: inline;
}

.gradient-text-1::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px; /* Regola lo spessore della linea di sottolineatura */
  background: linear-gradient(to top right, #B0D99C, #68BE6A);
  bottom: 12px;
  left: 50%; /* Centra il pseudo-elemento */
  width: calc(100% - 26px); /* Riduce la larghezza totale di 20px */
  transform: translateX(-50%); 
}

.no-border {

}

.gradient-border-wrapper {
  position: relative;
  border-radius: 8px;
  display: inline-block;
}

.gradient-border-wrapper {
  display: inline-block;
  border-radius: 8px; /* Raggio del bordo esterno */
  border: 2px solid transparent; /* Bordo trasparente per la dimensione */
  background: linear-gradient(white, white) padding-box, 
              linear-gradient(to top right, #B0D99C, #68BE6A) border-box; /* Gradiente solo sul bordo */
}

.gradient-border-wrapper-r {
  position: relative;
  border-radius: 8px;
  display: inline-block;
}

.gradient-border-wrapper-r {
  display: inline-block;
  border-radius: 8px; /* Raggio del bordo esterno */
  border: 2px solid transparent; /* Bordo trasparente per la dimensione */
  background: linear-gradient(white, white) padding-box, 
              red; /* Gradiente solo sul bordo */
}

.gradient-button {
  background: white; /* Sfondo bianco del bottone */
  border: none;
  padding: 8px 16px;
  font-weight: bold;
  width: 100%;
  height: 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to top right, #B0D99C, #68BE6A); /* Gradiente applicato al testo */
}

.red-button {
  background: white; /* Sfondo bianco del bottone */
  border: none;
  padding: 8px 16px;
  font-weight: bold;
  width: 100%;
  height: 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to top right, red, red); /* Gradiente applicato al testo */
}

button.different-class{
  border-radius: 8px;
  background: linear-gradient(to top right, #B0D99C, #68BE6A); /* Sfondo del bottone */
  border: none;
  padding: 6px 12px;
  color: white;
}

/* Stili per il loader */
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #2f1847; /* Dark purple */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Stili per il contenitore del loader */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Imposta l'altezza al 100% dell'area visibile dello schermo */
  width: 75vw; /* Imposta la larghezza al 100% dell'area visibile dello schermo */
}

.dx-datagrid-focus-overlay {
  border: 0px;
}

.custom-row-style {
  background-color: grey; 
  display: flex; 
  width: 100%;

}

.custom-step-icon {
  color: rgba(70, 72, 104, 1); /* Set your desired icon color here */
}